<template>
  <v-container
    :key="componentKey"
    fluid
    fill-height
    class="pa-3"
  >
    <v-row dense>
      <v-col
        cols="12"
        sm="2"
      >
        <v-card
          v-if="tab === 0"
          :height="($vuetify.breakpoint.height - 72).toString()"
        >
          <v-list
            style="overflow-y: auto;"
            :height="($vuetify.breakpoint.height - 72).toString()"
          >
            <v-list-group
              :value="true"
              dense
              prepend-icon="mdi-at"
              class="mt-n2"
            >
              <v-icon
                slot="prependIcon"
                size="20"
              >
                mdi-at
              </v-icon>
              <template
                v-slot:activator
              >
                <v-list-item-title
                  class="ml-n8"
                  style="font-size: 16px; font-weight: bold;"
                >
                  {{ $t('mixed|suggestions') }}
                </v-list-item-title>
              </template>
              <div
                v-if="actualTemplateAndQuestionairreSuggestions.length"
              >
                <v-list-item
                  v-for="(suggestion, index) in actualTemplateAndQuestionairreSuggestions"
                  :key="index"
                  three-line
                  dense
                  @click="scrollToSuggestion(suggestion)"
                >
                  <v-list-item-content
                    v-if="suggestion"
                  >
                    <template
                      v-slot:activator
                    >
                      <v-list-item-title>
                        {{ $t('mixed|suggestions') }}
                      </v-list-item-title>
                    </template>
                    <v-list-item-title
                      class="primary--text"
                    >
                      @{{ suggestion.customLabel ? suggestion.label + '[' + suggestion.customLabel + ']' : suggestion.label ? suggestion.label : suggestion.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                      class="mt-1"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ inUse(suggestion) ? 'mdi-checkbox-marked-circle-outline' : icons.notInUseInQuestionairre }}
                      </v-icon>
                      {{ inUse(suggestion) ? $t('mixed|in_use_in_questionnaire') : $t('mixed|not_in_use_in_questionnaire') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div
                v-else
                class="ml-5"
                style="font-size: 14px"
              >
                {{ $t('mixed|no_actual_suggestions') }}
              </div>
            </v-list-group>
          </v-list>
        </v-card>

        <v-card
          v-if="tab === 1"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <QuestionnaireSuggestionsPanel
            v-if="templateData"
            :template-data="templateData"
            :options="{
              hasClose: false,
              inCase: false,
              inCaseModel: false,
              inTemplate: true,
              isVisibleDocuments: false,
              isVisibleSuggestions: true
            }"
            @addField="addNewFieldInTemplateQuestionnaire"
            @addAllUnusedFields="addAllNewFieldsInTemplateQuestionnaire"
            @updateField="updateTemplateQuestionnaireField"
            @deleteField="deleteTemplateQuestionnaireField"
          />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="8"
      >
        <v-card
          class="pa-2"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <TemplateQuestionnaireForm
            ref="TemplateQuestionnaireForm"
            :template-data="templateData"
            :options="{
              hasClose: true,
              inCase: false,
              inCaseModel: false,
              inTemplate: true
            }"
            :tab-change="tab"
            :scroll-to-element="scrollToElement"
            @tab-changed="tabChanged"
            @addField="addNewFieldInTemplateQuestionnaire"
            @addAllUnusedFields="addAllNewFieldsInTemplateQuestionnaire"
            @updateField="updateTemplateQuestionnaireField"
            @change-field-order="updateQuestionnaireFieldsOrderTemplate"
            @deleteField="deleteTemplateQuestionnaireField"
            @save="saveTemplate"
            @update-template-name="updateTemplateName"
            @update-template-description="updateTemplateDescription"
            @closeTemplate="closeTemp"
            @scroll-success="onScrollSuccess"
          />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="2"
      >
        <v-card
          v-if="tab === 0"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <v-list
            :max-height="($vuetify.breakpoint.height - 117).toString()"
          >
            <v-list-group
              v-if="templateData"
              :value="true"
              dense
              class="mt-n2"
            >
              <v-icon
                slot="prependIcon"
                class="mr-n6"
              >
                mdi-file-outline
              </v-icon>
              <template
                v-slot:activator
              >
                <v-list-item-title
                  class="ml-n5"
                >
                  <span style="font-size: 16px; font-weight: bold; white-space: normal;">
                    {{ $t('documents|required_documents') }}
                  </span>
                </v-list-item-title>
              </template>
              <div v-if="requestedDocumentsInQuestionairre.length">
                <v-list-item
                  v-for="suggestion in requestedDocumentsInQuestionairre"
                  :key="suggestion.id"
                  three-line
                  dense
                  @click="scrollToSuggestion(suggestion)"
                >
                  <v-list-item-content>
                    <template
                      v-slot:activator
                    >
                      <span>
                        {{ $t('documents|required_documents') }}
                      </span>
                    </template>
                    <v-list-item-title
                      class="primary--text"
                    >
                      {{ translateFileInputField(suggestion, actualLanguage) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ inUse(suggestion) ? 'mdi-checkbox-marked-circle-outline' : icons.notInUseInQuestionairre }}
                      </v-icon>
                      {{ inUse(suggestion) ? $t('mixed|in_use_in_questionnaire') : $t('mixed|not_in_use_in_questionnaire') }}
                    </v-list-item-subtitle>

                    <!-- <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ icons.notUploaded }}
                      </v-icon>
                      {{ $t('clients|not_uploaded_by_client') }}
                    </v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div
                v-else
                class="ml-5"
                style="font-size: 14px"
              >
                {{ $t('expressions|no_actual_required_documents') }}
              </div>
            </v-list-group>
          </v-list>
        </v-card>

        <v-card
          v-if="tab === 1"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <QuestionnaireSuggestionsPanel
            v-if="templateData"
            :template-data="templateData"
            :options="{
              hasClose: false,
              inCase: false,
              inCaseModel: false,
              inTemplate: true,
              isVisibleDocuments: true,
              isVisibleSuggestions: false
            }"
            @addField="addNewFieldInTemplateQuestionnaire"
            @addAllUnusedFields="addAllNewFieldsInTemplateQuestionnaire"
            @updateField="updateTemplateQuestionnaireField"
            @deleteField="deleteTemplateQuestionnaireField"
            @add-new-document-in-template-questionnaire="onAddNewDocument"
          />
        </v-card>
      </v-col>
    </v-row>
    <ChangeTemplateCategory
      :template="templateData"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { uniqBy } from '@/utils/utils'
import { mdiTrashCanOutline, mdiChevronDown, mdiChevronUp, mdiPinOutline, mdiFile, mdiClose, mdiTextBoxSearchOutline, mdiCommentOffOutline, mdiDatabaseOffOutline, mdiUploadOffOutline } from '@mdi/js'
import TemplateQuestionnaireForm from '@/components/forms/TemplateQuestionnaireForm.vue'
import ChangeTemplateCategory from '../../components/dialogs/ChangeTemplateCategory.vue'
import QuestionnaireSuggestionsPanel from '../../components/forms/QuestionnaireFieldEdit/QuestionnaireSuggestionsPanel.vue'
import generalMixin from '@/utils/generalMixin'

export default {
  components: {
    TemplateQuestionnaireForm,
    ChangeTemplateCategory,
    QuestionnaireSuggestionsPanel
  },
  mixins: [generalMixin],
  data: () => ({
    icons: {
      trash: mdiTrashCanOutline,
      arrowDown: mdiChevronDown,
      arrowUp: mdiChevronUp,
      pin: mdiPinOutline,
      file: mdiFile,
      close: mdiClose,
      textBox: mdiTextBoxSearchOutline,
      notInUseInQuestionairre: mdiCommentOffOutline,
      emptyDatabase: mdiDatabaseOffOutline,
      notUploaded: mdiUploadOffOutline
    },
    toolbarHeight: localStorage.getItem('toolbarHeightInTemplates') || '50px',
    templateData: {},
    tab: 0,
    componentKey: 1,
    scrollToElement: null,
    actualLanguage: null
  }),
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      templates: state => state.templates.templates,
      companyTemplates: state => state.companyTemplates.companyTemplates,
      groupTemplates: state => state.groupTemplates.groupTemplates,
      actualTabTemplates: state => state.templates.actualTabTemplates,
      actualSuggestionsEditorStorage: state => state.editorStore.actualSuggestionsEditorStorage
    }),
    actualTemplateAndQuestionairreSuggestions () {
      let suggestions = []
      let finalFilter = []
      if (this.actualSuggestionsEditorStorage.length) {
        this.actualSuggestionsEditorStorage.forEach(element => {
          suggestions.push(element.attrs)
        })
      }
      if (this.templateData.questionnaire.fields && this.templateData.questionnaire.fields.length) {
        this.templateData.questionnaire.fields.forEach(field => {
          if (field.fieldType !== 'file-input') {
            suggestions.push(field)
          }
        })
      }
      if (suggestions.length) {
        finalFilter = uniqBy(suggestions, 'id')
      }
      return finalFilter
    },
    requestedDocumentsInQuestionairre () {
      let requestedDocs = []
      if (this.templateData.questionnaire.fields && this.templateData.questionnaire.fields.length) {
        this.templateData.questionnaire.fields.forEach(field => {
          if (field.fieldType === 'file-input') {
            requestedDocs.push(field)
          }
        })
      }
      return requestedDocs
    }
  },
  watch: {
    // here we are watching for changes in the template data and updating it,
    // so the template data stays actual also in the quastionnaire
    templates: {
      handler (value) {
        const tempIndex = this.templates.findIndex(t => t._id === this.$route.params._id)
        this.templateData = this.templates[tempIndex]
      },
      immediate: true
    },
    companyTemplates: {
      handler (value) {
        const tempIndex = this.companyTemplates.findIndex(t => t._id === this.$route.params._id)
        this.templateData = this.companyTemplates[tempIndex]
      },
      immediate: true
    },
    groupTemplates: {
      handler (value) {
        const tempIndex = this.groupTemplates.findIndex(t => t._id === this.$route.params._id)
        this.templateData = this.groupTemplates[tempIndex]
      },
      immediate: true
    }
  },
  mounted () {
    this.actualLanguage = localStorage.getItem('preferedLanguage')
    window.addEventListener('pref-language-changed', (event) => {
      this.actualLanguage = event.detail.lang
    })
  },
  async created () {
    const temp = this.templates.find(t => t._id === this.$route.params._id) ||
    this.companyTemplates.find(t => t._id === this.$route.params._id) ||
    this.groupTemplates.find(t => t._id === this.$route.params._id)
    if (!temp) {
      try {
        const { data } = await this.$axios.get(`/getTemplate/${this.$route.params._id}`)
        this.templateData = data.data
      } catch (e) {
        console.error(e, 'err')
      }
    } else {
      this.templateData = temp
      this.closeLeftDrawerMenu()
    }
    EventBus.$on('refresh-view', this.refreshView)
  },
  beforeDestroy () {
    EventBus.$off('refresh-view', this.refreshView)
  },
  methods: {
    ...mapActions({
      updateTemplate: 'templates/updateTemplate',
      updateCompanyTemplate: 'companyTemplates/updateCompanyTemplate',
      updateGroupTemplate: 'groupTemplates/updateGroupTemplate',
      deleteTemplate: 'templates/deleteTemplate',
      updateQuestionnaireInTemplate: 'questionnaire/updateQuestionnaireInTemplate',
      addToast: 'toasts/addToast'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    refreshView () {
      this.componentKey++
    },
    closeTemp () {
      this.$router.push({ path: '/templates' })
    },
    tabChanged (tab) {
      this.tab = tab
    },
    closeLeftDrawerMenu () {
      this.toggleDrawer(false)
    },
    onAddNewDocument () {
      this.$refs.TemplateQuestionnaireForm.selectedFieldType = 'file-input'
      this.$refs.TemplateQuestionnaireForm.e6 = 2
      this.$refs.TemplateQuestionnaireForm.addNewFieldDialog = true
    },
    async addNewFieldInTemplateQuestionnaire (newField) {
      try {
        const payload = {
          field: newField,
          addNewField: true
        }
        const _id = this.templateData.questionnaire._id
        const templateId = this.templateData._id
        await this.updateQuestionnaireInTemplate({ _id, templateId, payload })
        const element = document.getElementById(newField.id)
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async addAllNewFieldsInTemplateQuestionnaire (newFields) {
      try {
        const payload = {
          fields: newFields,
          addNewMultipleFields: true
        }
        const _id = this.templateData.questionnaire._id
        const templateId = this.templateData._id
        await this.updateQuestionnaireInTemplate({ _id, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateTemplateQuestionnaireField (field) {
      try {
        const payload = {
          field: field,
          updateFieldContent: true
        }
        const _id = this.templateData.questionnaire._id
        const templateId = this.templateData._id
        await this.updateQuestionnaireInTemplate({ _id, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateQuestionnaireFieldsOrderTemplate (actualFields) {
      try {
        const payload = {
          fields: actualFields,
          newFieldsOrder: true
        }
        const _id = this.templateData.questionnaire._id
        const templateId = this.templateData._id
        await this.updateQuestionnaireInTemplate({ _id, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async deleteTemplateQuestionnaireField (field) {
      try {
        const payload = {
          fieldToBeDeleted: field.fieldToBeDeleted,
          deleteFieldFromQuestionnaire: true
        }
        const _id = this.templateData.questionnaire._id
        const templateId = this.templateData._id
        await this.updateQuestionnaireInTemplate({ _id, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async saveTemplate (payload) {
      const myTemp = this.templates.find(t => t._id === this.$route.params._id)
      const companyTemp = this.companyTemplates.find(t => t._id === this.$route.params._id)
      const groupTemp = this.groupTemplates.find(t => t._id === this.$route.params._id)
      if (this.actualTabTemplates.isMyTab || myTemp) {
        try {
          await this.updateTemplate(payload)
          this.addToast({
            title: this.$t('common|edited_successfully'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch {
          this.addToast({
            title: this.$t('common|edit_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      } else if (this.actualTabTemplates.isCompanyTab || companyTemp) {
        try {
          await this.updateCompanyTemplate(payload)
          this.addToast({
            title: this.$t('common|edited_successfully'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch {
          this.addToast({
            title: this.$t('common|edit_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      } else if (this.actualTabTemplates.isGroupTab || groupTemp) {
        try {
          await this.updateGroupTemplate(payload)
          this.addToast({
            title: this.$t('common|edited_successfully'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch {
          this.addToast({
            title: this.$t('common|edit_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    async updateTemp (payload) {
      const myTemp = this.templates.find(t => t._id === this.$route.params._id)
      const companyTemp = this.companyTemplates.find(t => t._id === this.$route.params._id)
      const groupTemp = this.groupTemplates.find(t => t._id === this.$route.params._id)
      if (this.actualTabTemplates.isMyTab || myTemp) {
        try {
          await this.updateTemplate(payload)
          this.addToast({
            title: this.$t('common|edited_successfully'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch {
          this.addToast({
            title: this.$t('common|edit_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      } else if (this.actualTabTemplates.isCompanyTab || companyTemp) {
        try {
          await this.updateCompanyTemplate(payload)
          this.addToast({
            title: this.$t('common|edited_successfully'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch {
          this.addToast({
            title: this.$t('common|edit_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      } else if (this.actualTabTemplates.isGroupTab || groupTemp) {
        try {
          await this.updateGroupTemplate(payload)
          this.addToast({
            title: this.$t('common|edited_successfully'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch {
          this.addToast({
            title: this.$t('common|edit_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    // async addField (payload) {
    //   try {
    //     await this.updateTemplate(payload)
    //   } catch {
    //     this.addToast({
    //       title: this.$t('common|edit_fail'),
    //       color: 'error'
    //     })
    //   }
    // },
    // async updateField (payload) {
    //   try {
    //     await this.updateTemplate(payload)
    //   } catch (e) {
    //     console.error(e, 'error in field update')
    //     this.addToast({
    //       title: this.$t('common|edit_fail'),
    //       color: 'error'
    //     })
    //   }
    // },
    // async deleteField (payload) {
    //   try {
    //     await this.updateTemplate(payload)
    //     this.addToast({
    //       title: this.$t('common|edited_successfully'),
    //       color: 'success'
    //     })
    //   } catch {
    //     this.addToast({
    //       title: this.$t('common|edit_fail'),
    //       color: 'error'
    //     })
    //   }
    // },
    updateCategory (category) {
      EventBus.$emit('updateTempCat', category)
    },
    async updateTemplateName (templateData, type) {
      this.loading = true
      let res
      if (type && type === 'from editor') {
        res = templateData.name
      } else {
        res = await this.$dialog.prompt({
          title: this.$t('templates|template_name'),
          text: this.$t('actions|enter_template_name'),
          textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
            type: 'text'
          }
        })
      }
      if (!res) return
      const payload = {
        name: res
      }
      const myTemp = this.templates.find(t => t._id === this.$route.params._id)
      const companyTemp = this.companyTemplates.find(t => t._id === this.$route.params._id)
      const groupTemp = this.groupTemplates.find(t => t._id === this.$route.params._id)
      if (this.actualTabTemplates.isMyTab || myTemp) {
        try {
          await this.updateTemplate({ _id: templateData._id, payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (this.actualTabTemplates.isCompanyTab || companyTemp) {
        try {
          await this.updateCompanyTemplate({ _id: templateData._id, payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (this.actualTabTemplates.isGroupTab || groupTemp) {
        try {
          await this.updateGroupTemplate({ _id: templateData._id, payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
    },
    async updateTemplateDescription (templateData) {
      this.loading = true
      const res = await this.$dialog.prompt({
        title: this.$t('common|description'),
        text: this.$t('actions|add_new_description'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [{
          text: this.$t('actions|submit'), color: 'primary'
        }]
      })
      if (!res) return
      const payload = {
        description: res
      }
      const myTemp = this.templates.find(t => t._id === this.$route.params._id)
      const companyTemp = this.companyTemplates.find(t => t._id === this.$route.params._id)
      const groupTemp = this.groupTemplates.find(t => t._id === this.$route.params._id)
      if (this.actualTabTemplates.isMyTab || myTemp) {
        try {
          await this.updateTemplate({ _id: templateData._id, payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (this.actualTabTemplates.isCompanyTab || companyTemp) {
        try {
          await this.updateCompanyTemplate({ _id: templateData._id, payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (this.actualTabTemplates.isGroupTab || groupTemp) {
        try {
          await this.updateGroupTemplate({ _id: templateData._id, payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
    },
    inUse (suggestion) {
      let inUse
      if (this.templateData.questionnaire.fields && this.templateData.questionnaire.fields.length) {
        inUse = this.templateData.questionnaire.fields.find(f => f.id === suggestion.id)
      }
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    scrollToSuggestion (suggestion) {
      if (suggestion && suggestion.fieldType && suggestion.fieldType === 'file-input') {
        const usedInQuestionnaire = this.inUse(suggestion)
        if (usedInQuestionnaire) {
          this.scrollToElement = {
            id: suggestion.id
          }
          this.tab = 1
        }
      } else {
        const element = document.getElementById(suggestion.id)
        if (element && element.className === 'mention') {
          element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
          element.style.backgroundColor = 'red'
          setTimeout(() => {
            element.style.backgroundColor = ''
          }, '1000')
        } else if (element && element.className !== 'mention') {
          this.addToast({
            title: this.$t('suggestions|suggestion_is_not_in_use_in_template'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    onScrollSuccess () {
      this.scrollToElement = null
    }
  }
}
</script>

<style lang="scss" scoped>
.template-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  overflow: hidden;
  z-index: 0;
}
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0em;
  z-index: 2;
}
.icon-position {
  position: absolute;
  top: 2%;
  right: 1%;
}
</style>
