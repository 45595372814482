<template>
  <v-dialog
    v-model="changeTemplateCategoryDialog"
    persistent
    keydown
    max-width="600px"
    transition="dialog-bottom-transition"
  >
    <v-container
      fill-height
      fluid
      class="pa-1 ma-0"
      style="background-color: white;"
    >
      <v-card
        flat
        width="100%"
      >
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t('actions|change_category') }}
          </v-toolbar-title>
          <v-spacer />
          <v-icon
            @click="changeTemplateCategoryDialog = false"
          >
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-select
          v-model="selectedCategory"
          outlined
          dense
          :label="defaultCategory"
          :items="availableCategories"
          item-text="value"
          item-value="value"
          class="mx-4 mt-2"
        >
          <template
            slot="item"
            slot-scope="data"
          >
            <v-list
              v-model="selectedCategory"
            >
              <v-list-item
                three-line
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon
                      x-small
                    >
                      mdi-tag-outline
                    </v-icon>
                    {{ data.item.value }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('mixed|category_created_by') }}:</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ data.item.createdBy }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-select>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            dense
            small
            rounded
            min-width="150"
            class="mb-1"
            :loading="isLoading"
            @click="changeTemplateCategory(); changeTemplateCategoryDialog = false"
          >
            {{ $t('actions|submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    template: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedCategory: {},
      defaultCategory: '',
      changeTemplateCategoryDialog: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    availableCategories () {
      let categories = []
      if (this.template.sharedWith.accounts.length) {
        this.template.sharedWith.accounts.forEach(acc => {
          acc.myTemplateCategories.forEach(cat => {
            const newCat = {
              createdBy: acc.accountName ? acc.accountName : this.$t('mixed|account_no_longer_available'),
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      if (this.template.sharedWith.company.length) {
        this.template.sharedWith.company.forEach(company => {
          company.companyTemplatesCategories.forEach(cat => {
            const newCat = {
              createdBy: company.companyData.companyName,
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      if (this.template.sharedWith.groups.length) {
        this.template.sharedWith.groups.forEach(group => {
          group.groupTemplatesCategories.forEach(cat => {
            const newCat = {
              createdBy: group.groupName,
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      return categories
    }
  },
  created () {
    EventBus.$on('updateTempCat', this.openDialog)
  },
  beforeDestroy () {
    EventBus.$off('updateTempCat', this.openDialog)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateTemplate: 'templates/updateTemplate'
    }),
    updateCategory () {
      this.openDialog()
    },
    async changeTemplateCategory () {
      this.loading = true
      const payload = {
        category: this.selectedCategory
      }
      try {
        await this.updateTemplate({ _id: this.template._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    openDialog (category) {
      if (category === 'none') {
        category = this.$t('actions|select_category')
      }
      this.defaultCategory = category
      this.changeTemplateCategoryDialog = true
    }
  }
}
</script>
